<template>
  <v-dialog v-model="dialog" max-width="700" persistent>
    <v-card v-if="isLoading">
      <v-card-title>
        <menu-title title="Sending..." class="pt-0 pb-0 mb-0"></menu-title>
      </v-card-title>
      <v-card-text>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-card-title>
        <menu-title
          title="Request confirmation"
          class="pt-0 pb-0 mb-0"
        ></menu-title>
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              <span
                >Review requested
                {{ amount == 1 ? "license" : "licenses" }}
              </span>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              <span>Terms of service</span>
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card flat>
                <v-subheader class="mt-0 pt-0">
                  <span>You selected the following license details:</span>
                </v-subheader>
                <v-divider></v-divider>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td align="left">License</td>
                      <td align="right">{{ name }}</td>
                    </tr>
                    <tr>
                      <td align="left">Amount</td>
                      <td align="right">
                        {{ amount }} {{ amount == 1 ? "License" : "Licenses" }}
                      </td>
                    </tr>
                    <!-- <tr v-if="subscription">
                      <td align="left">Runtime</td>
                      <td align="right">
                        {{ duration }} {{ duration == 1 ? "Month" : "Months" }}
                      </td>
                    </tr> -->
                  </tbody>
                </v-simple-table>
                <v-subheader class="mt-0 pt-0">
                  <span>For the following price:</span>
                </v-subheader>
                <v-divider></v-divider>
                <v-simple-table>
                  <tbody>
                    <tr>
                      <td align="left">Total (including discounts)</td>
                      <td align="right">{{ total }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-card>

              <v-row class="mt-6" no-gutters>
                <v-col cols="6" align="left">
                  <v-btn outlined @click="dialog = false">Cancel</v-btn>
                </v-col>
                <v-col cols="6" align="right">
                  <v-btn
                    outlined
                    color="#b31616"
                    class="ml-2"
                    @click="step = 2"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card flat>
                <v-container fill-height fluid>
                  <v-row align="center" justify="center">
                    <v-col cols="11" align="center" justify="center">
                      <v-checkbox v-model="tos">
                        <template v-slot:label>
                          <span>
                            I agree to the COBISOFT Partner Portal Terms of
                            Service
                          </span>
                        </template>
                      </v-checkbox>
                    </v-col>
                    <v-col cols="1" align="center" justify="center">
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-on="on"
                            v-bind="attrs"
                            icon
                            small
                            @click="tosDialog = true"
                          >
                            <v-icon>mdi-open-in-new</v-icon>
                          </v-btn>
                        </template>
                        Open ToS
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>

              <v-row class="mt-6" no-gutters>
                <v-col cols="6" align="left">
                  <v-btn outlined @click="dialog = false">Cancel </v-btn>
                </v-col>
                <v-col cols="6" align="right">
                  <v-btn
                    outlined
                    color="primary"
                    class="ml-2"
                    :disabled="!tos"
                    @click="request"
                  >
                    Send Request
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>

      <terms-of-service-dialog
        @accept="tos = true"
        v-model="tosDialog"
      ></terms-of-service-dialog>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapActions } from "vuex";
import menuTitle from "../helpers/menu_title.vue";
import termsOfServiceDialog from "./customer_license_request_confirmation_dialog_tos_dialog.vue";
export default {
  name: "requestConfirmationDialog",
  props: {
    subcustomer: {
      type: Object,
      required: true,
    },
    template: {
      type: Number,
      required: true,
    },
    value: Boolean,
    name: String,
    amount: Number,
    duration: Number,
    total: String,
    subscription: Boolean,
    parentDialog: Boolean,
    nfr: Boolean,
  },
  components: {
    menuTitle,
    termsOfServiceDialog,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data: () => ({
    step: 1,
    tos: false,
    tosDialog: false,

    isLoading: false,
  }),
  methods: {
    ...mapActions({ showSnackbar: "showSnackbar" }),
    request: function () {
      //Only used to group Licenses
      let purchaseid = String(Date.now());
      purchaseid += "_" + Math.random().toString().slice(2, 12);

      console.log(this.subcustomer);
      console.log(this.template);
      this.isLoading = true;
      Vue.prototype.$axios
        .post("/customerlicenses", {
          months: this.duration,
          nfr: this.nfr,
          subcustomerid: this.subcustomer.customerid,
          templateid: this.template,
          subscription: this.subscription,
          purchaseid: purchaseid,
          amount: this.amount,
        })
        .then((response) => {
          this.isLoading = false;
          let str = "License";
          if (this.amount > 1) str += "s";
          this.showSnackbar(
            str + " requested" + (response.data ? ` , ${response.data}` : "")
          );
          this.parentDialog = false;
          this.dialog = false;
          this.$emit("close");
        })
        .catch((error) => {
          console.log(error);
          this.showSnackbar(
            "License/s could not be requested. Error: {0}".format(error)
          );
        });
    },
  },
};
</script>

<style>
</style>