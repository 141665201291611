var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"1500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[(_vm.loggedInUserIsAdmin())?_c('menu-title',{attrs:{"title":"Create License"}}):_c('menu-title',{attrs:{"title":"License Request"}})],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{staticClass:"ml-3",attrs:{"align":"left","color":"primary","inset":"","label":"Rental licence","disabled":_vm.disableSubSwitch},model:{value:(_vm.subscription),callback:function ($$v) {_vm.subscription=$$v},expression:"subscription"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{staticClass:"ml-3",attrs:{"align":"left","color":"primary","inset":"","label":"Partner Testlicense"},on:{"change":_vm.nfrToggled},model:{value:(_vm.nfr),callback:function ($$v) {_vm.nfr=$$v},expression:"nfr"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{staticClass:"ml-3",attrs:{"items":_vm.allApplications,"item-text":(item) => item.name,"item-value":(item) => item.applicationid,"outlined":"","label":"Application","hide-details":""},on:{"change":function($event){_vm.selectedTemplate = _vm.selectDefault()}},model:{value:(_vm.applicationid),callback:function ($$v) {_vm.applicationid=$$v},expression:"applicationid"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","min":"1","label":"Amount","outlined":"","hide-details":"","rules":[_vm.exceedsLimit]},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}})],1)],1),(_vm.applicationid)?_c('v-row',[(_vm.templatesByApplicationId(_vm.applicationid).length == 0)?_c('label',[_vm._v(" No available licenses for selected application ")]):_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.templatesByApplicationId(
                _vm.applicationid
              )),function(template,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('v-card',{attrs:{"outlined":"","dense":""}},[_c('v-card-title',{staticClass:"py-0"},[_c('span',{staticStyle:{"color":"#b31616"}},[_vm._v(_vm._s(template.name))]),_c('v-spacer'),_c('v-checkbox',{staticClass:"py-0",attrs:{"value":template.id,"color":"primary"},model:{value:(_vm.selectedTemplate),callback:function ($$v) {_vm.selectedTemplate=$$v},expression:"selectedTemplate"}})],1),_c('v-card-text',{staticClass:"pt-0 pb-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8","align":"left"}},[_vm._v(" "+_vm._s(template.description)+" ")]),_c('v-col',{attrs:{"cols":"4","align":"right"}},[_vm._v(" "+_vm._s(_vm.subscription ? _vm.nfr ? "0€ / Month" : template.monthlyprice + "€ / Month" : _vm.nfr ? "0€" : template.price + "€")+" ")])],1)],1)],1)],1)}),1),(!_vm.subscription)?_c('v-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('span',{staticStyle:{"font-size":"115%"}},[_c('b',[_vm._v("Software maintenance fee per licence 10% of the end customer price p.a.")])]),_c('br'),_c('span',{staticStyle:{"font-size":"95%"}},[_c('b',[_vm._v("Invoice and software maintenance contract will be issued separately")])])]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{attrs:{"align":"center"}},[_c('v-timeline',{attrs:{"dense":"","clipped":""}},_vm._l((_vm.discounts),function(discount,index){return _c('v-timeline-item',{key:index,attrs:{"fill-dot":"","color":discount.important ? '#b3161699' : '#66666699',"medium":discount.important,"small":!discount.important}},[_c('v-text-field',{style:(discount.important
                        ? 'font-weight:bold'
                        : 'font-weight:normal'),attrs:{"disabled":"","hide-details":"","solo":"","label":discount.text},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{style:(discount.important
                            ? 'font-weight:bold'
                            : 'font-weight:normal')},[_vm._v(" "+_vm._s(_vm.nfr ? "0€" : discount.value)+" ")])]},proxy:true}],null,true)})],1)}),1)],1)],1)],1)],1):_vm._e()],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-3",attrs:{"outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_c('span',[_vm._v("Cancel")])]),_c('v-spacer'),(_vm.loggedInUserIsAdmin())?_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.createNewLicenseForCustomer()}}},[_c('span',[_vm._v("Create")])]):_c('v-btn',{staticClass:"mr-3",attrs:{"outlined":"","color":"primary"},on:{"click":function($event){return _vm.beforeRequestConfirmation()}}},[_vm._v(" Request ")])],1)],1),(_vm.requestConfirmationDialog)?_c('request-confirmation-dialog',{attrs:{"name":_vm.templateById(_vm.selectedTemplate).name,"amount":_vm.amount,"duration":_vm.subscriptionLength,"total":_vm.nfr ? '0€' : _vm.discounts[_vm.discounts.length - 1].value,"subscription":_vm.subscription,"subcustomer":_vm.subcustomer,"template":_vm.selectedTemplate,"parentDialog":_vm.dialog,"nfr":_vm.nfr},on:{"accepted":_vm.requestLicense,"close":function($event){_vm.dialog = false}},model:{value:(_vm.requestConfirmationDialog),callback:function ($$v) {_vm.requestConfirmationDialog=$$v},expression:"requestConfirmationDialog"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }