<template>
  <v-dialog v-model="dialog" max-width="1500" persistent>
    <v-card>
      <v-card-title>
        <menu-title
          :title="subcustomerExists() ? 'Edit customer' : 'New Customer'"
        ></menu-title>
      </v-card-title>
      <v-card-text>
        <v-divider></v-divider>
        <v-subheader>
          <span>Company Information:</span>
        </v-subheader>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="tmpSubcustomer.name"
              outlined
              label="Company Name *"
              :rules="[requiredRule]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <!--
          <v-col cols="2">
            <v-switch
              v-model="tmpSubcustomer.maintenance_active"
              :label="tmpSubcustomer.maintenance_active ? 'Maintenance: active' : 'Maintenance: inactive'"
              color="#b31616"
            ></v-switch>
          </v-col>
          -->

          <v-col cols="8">
            <v-text-field
              v-model="tmpSubcustomer.street"
              outlined
              label="Street"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="tmpSubcustomer.hnumber"
              outlined
              label="House / Building Number"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="tmpSubcustomer.postal"
              outlined
              label="Postal Code"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="tmpSubcustomer.city"
              outlined
              label="City *"
              :rules="[requiredRule]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="tmpSubcustomer.country"
              outlined
              label="Country *"
              :rules="[requiredRule]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="tmpSubcustomer.industry"
              outlined
              label="Industry"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="tmpSubcustomer.website"
              outlined
              label="Website *"
              :rules="[requiredRule]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-subheader>
          <span>Contact Details:</span>
        </v-subheader>
        <v-row>
          <v-col cols="3">
            <v-text-field
              v-model="tmpSubcustomer.titel"
              outlined
              label="Titel *"
              :rules="[requiredRule]"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              label="Forename *"
              v-model="tmpSubcustomer.contactforename"
              :rules="[requiredRule]"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              outlined
              label="Middlename"
              v-model="tmpSubcustomer.contactmiddlename"
              hide-details="auto"
            >
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="tmpSubcustomer.contact"
              outlined
              label="Surname *"
              :rules="[requiredRule]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="tmpSubcustomer.position"
              outlined
              label="Position *"
              :rules="[requiredRule]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="tmpSubcustomer.email"
              outlined
              label="E-Mail *"
              :rules="[requiredRule]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="tmpSubcustomer.tel"
              outlined
              label="Telephone Number *"
              :rules="[requiredRule]"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined @click="dialog = false" class="ml-3">
          <span>Cancel</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined @click="subcustomerExists() ? save() : add()" class="mr-3">
          <span v-if="subcustomerExists()">Save Changes</span>
          <span v-else>Add Customer</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import menuTitle from "../helpers/menu_title.vue";
export default {
  name: "customerDialog",
  components: {
    menuTitle,
  },
  props: {
    value: Boolean,
    subcustomer: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      newSubcustomer: "newSubcustomer",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data: () => ({
    tmpSubcustomer: {},
    requiredRule: (value) => !!value || "Required",
  }),
  methods: {
    ...mapActions({
      post: "postSubcustomer",
      patch: "patchSubcustomer",
      fetchSubcustomerAsnyc: "fetchSubcustomerAsync",
      showSnackbar: "showSnackbar",
    }),
    subcustomerExists() {
      return this.subcustomer && this.subcustomer.name;
    },
    save: function () {
      console.log("saving customer...");
      console.log(this.tmpSubcustomer);
      this.patch(this.tmpSubcustomer).then((error) => {
        if (error) {
          this.showSnackbar("Customer could not be updated. Error: " + error);
        } else {
          this.showSnackbar("Customer has been updated.");
          this.dialog = false;
        }
      });
    },
    add: function () {
      console.log("adding customer...");
      console.log(this.tmpSubcustomer);
      this.post(this.tmpSubcustomer).then((error) => {
        if (error) {
          this.showSnackbar("Customer could not be added. Error: " + error);
        } else {
          this.showSnackbar("Customer has been added.");
          this.dialog = false;
        }
      });
    },
  },
  mounted() {
    if (this.subcustomer && this.subcustomer.customerof) {
      console.log(this.subcustomer);
      this.tmpSubcustomer = JSON.parse(JSON.stringify(this.subcustomer));
    } else {
      console.log("falsch");
      this.tmpSubcustomer = JSON.parse(JSON.stringify(this.newSubcustomer));
    }
  },
};
</script>

<style>
</style>