<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title>
        <menu-title title="Terms of Service"></menu-title>
      </v-card-title>
      <v-card-text>
        <pdf
          v-for="i in pageCount"
          :key="i"
          :src="source"
          :page="i"
          class="mb-6"
          style="display: inline-block; width: 100%; outline: auto"
        ></pdf>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined class="ml-2" @click="dialog = false"> Cancel </v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined class="ml-2" @click="dialog = false; $emit('accept')" color="primary">
          Accept
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import pdf from "vue-pdf";
import Vue from "vue";
import menuTitle from "../helpers/menu_title.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "termsOfServiceDialog",
  props: {
    value: Boolean,
  },
  components: {
    menuTitle,
    pdf,
  },
  data: () => ({
    isLoading: true,
    source: undefined,
    pageCount: undefined,
    tos: null,
    currentPage: undefined,
  }),
  computed: {
    ...mapGetters({
      allTermsofservices: "allTermsofservices",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      fetchTermsofservicesAsync: "fetchTermsofservicesAsync",
    }),
  },
  async mounted() {
    const toshelper = require("../../helpers/termsofservicehelper");
    this.tos = toshelper.getLatestTermsofserviceByLanguage(
      this.allTermsofservices,
      "en"
    );

    console.log("Mounted ToS Dialog");
    var apiBase = Vue.prototype.$axios.defaults.baseURL;
    var url = apiBase + this.tos.apipath;
    this.source = pdf.createLoadingTask(url);
    this.source.promise.then((pdf) => {
      this.pageCount = pdf.numPages;
      this.isLoading = false;
    });
  },
};
</script>

<style>
</style>