<template>
  <v-col>
    <v-row>
      <v-col>
        <v-select
          :items="allApplicationsForFiltering"
          item-text="name"
          item-value="applicationid"
          v-model="filterByApplicationId"
          outlined
          label="Filter by"
        ></v-select>
      </v-col>
      <!-- filter for different applications -->
      <v-col :align="'center'">
        <v-switch
          v-model="showPendingLicenses"
          :label="
            showPendingLicenses
              ? 'Showing active licenses'
              : 'Showing pending licenses'
          "
        >
        </v-switch>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="stretch">
        <v-data-table
          :items-per-page="-1"
          hide-default-footer
          :items="
            filterBy(
              showPendingLicenses
                ? customerlicensesBySubcustomerIdActiveLicenses(
                    customerid,
                    filterByApplicationId
                  )
                : customerlicensesBySubcustomerIdPendingLicenses(
                    customerid,
                    filterByApplicationId
                  ),
              filterByApplicationId
            )
          "
          :headers="customerLicenseTableHeader"
        >
          <template v-slot:item="{ item }">
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <tr
                  :class="getLicenseClass(item)"
                  v-on="item.issued ? null : on"
                  v-bind="item.issued ? null : attrs"
                >
                  <!-- select -->
                  <td v-if="selectionEnabled" align="center">
                    <v-checkbox
                      :value="selectedLicenses.includes(item.licenseid)"
                      @change="selected(item.licenseid)"
                      color="primary"
                    ></v-checkbox>
                  </td>

                  <!-- application -->
                  <td align="center">
                    {{ getApplicationName(item.templateid) }}
                  </td>

                  <!-- name -->
                  <td align="center">
                    {{ getLicenseName(item.templateid) }}
                  </td>

                  <!-- issued -->
                  <td align="center">
                    {{ item.issued ? getDateString(item.issued) : "-" }}
                  </td>

                  <!-- duration -->
                  <!-- <td align="center">
                    {{ item.subscription ? item.months + " Months" : "-" }}
                  </td>

                  <td align="center">
                    {{
                      item.subscription && item.expires
                        ? getDateString(item.expires)
                        : "-"
                    }}
                  </td> -->

                  <!-- price -->
                  <td align="center">
                    {{ getPrice(item) }}
                  </td>

                  <!-- maintenance fee -->
                  <td align="center">
                    {{ item.subscription ? getFee(item) : "-" }}
                  </td>
                  <td align="center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          v-if="item.issued"
                          @click="exportActivationKey(item.licenseid)"
                        >
                          mdi-export
                        </v-icon>
                      </template>
                      <span>Activationkey</span>
                    </v-tooltip>
                  </td>
                  <!-- <td v-if="loggedInUserIsAdmin()" align="center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          v-if="item.issued"
                          @click="revokeLicense(item.licenseid)"
                        >
                          mdi-eye-outline
                        </v-icon>
                      </template>
                      <span>Revoke or allow</span>
                    </v-tooltip>
                  </td> -->
                  <td v-if="loggedInUserIsAdmin()" align="center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-on="on"
                          v-bind="attrs"
                          @click="deleteDialog = true"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                    <accept-dialog
                      v-model="deleteDialog"
                      v-if="deleteDialog"
                      title="Delete License?"
                      text="Are you sure you want to delete this license?"
                      @accepted="deleteLicense(item.licenseid)"
                    >
                    </accept-dialog>
                  </td>
                </tr>
              </template>
              <span>Request pending</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { verify } from "../helpers/jwt";
import { mapActions, mapGetters } from "vuex";
import acceptDialog from "./helpers/generic_accept_dialog.vue";
export default {
  name: "licensesList",
  data() {
    return {
      showPendingLicenses: true,
      deleteDialog: false,
      filterByApplicationId: null,
    };
  },
  components: {
    acceptDialog,
  },
  props: {
    selectionEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    customerid: Number,
  },
  watch: {
    selectionEnabled: function () {
      this.selectedLicenses = [];
    },
  },
  methods: {
    ...mapActions({
      showSnackbar: "showSnackbar",
      getActivationKey: "getActivationKey",
      deleteCustomerlicense: "deleteCustomerlicense",
    }),
    revokeLicense(licenseId) {
      console.log("Needs to be implementes");
    },
    deleteLicense(licenseId) {
      console.log(licenseId);
      this.deleteCustomerlicense({ licenseid: licenseId })
        .then((e) => {
          console.log(e);
          if (e) {
            this.showSnackbar("Could not deleteLicense " + e);
          } else {
            this.showSnackbar("License " + licenseId + " was deleted.");
            deleteDialog = false;
          }
        })
        .catch((e) => console.log(e));
    },
    loggedInUserIsAdmin() {
      return verify(localStorage.getItem("jwt")).is_admin;
    },
    exportActivationKey: function (licenseid) {
      this.getActivationKey(licenseid).then((e) => {
        if (e) this.showSnackbar("Could not get License Key");
      });
    },
    selected: function (value) {
      //console.log(value);
      if (this.selectedLicenses.includes(value)) {
        var idx = this.selectedLicenses.findIndex((x) => x == value);
        this.selectedLicenses.splice(idx, 1);
      } else {
        this.selectedLicenses.push(value);
      }
    },
    getApplicationName: function (templateid) {
      var template = this.templateById(templateid);
      var application = this.applicationById(template.applicationid);
      return application.name;
    },
    filterBy: function (licenses, filterByApplicationId) {
      if (filterByApplicationId) {
        return licenses.filter((x) => {
          var template = this.templateById(x.templateid);
          return template.applicationid == filterByApplicationId;
        });
      }
      return licenses;
    },
    getLicenseName: function (templateid) {
      var template = this.templateById(templateid);
      return template.name;
    },
    getLicenseClass: function (customerlicense) {
      if (customerlicense.blocked) return "license_blocked";
      else if (!customerlicense.expires && !customerlicense.issued)
        return "license_pending";
      else if (customerlicense.expires - Date.now() < 604800000 * 1)
        return "license_expires_one_week";
      else if (customerlicense.expires - Date.now() < 604800000 * 2)
        return "license_expires_two_week";
      else if (customerlicense.expires - Date.now() < 604800000 * 4)
        return "license_expires_four_week";
    },
    getDateString: function (ms) {
      var date = new Date(Number(ms));
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Okt",
        "Nov",
        "Dez",
      ];
      var year = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes().toPrecision(2);
      var time = hours + ":" + minutes;
      return (
        date.getDate() + " " + months[date.getMonth()] + " " + year + " " + time
      );
    },
    getPrice: function (customerlicense) {
      if (customerlicense.pending || customerlicense.blocked) return "-";
      var price =
        //Number(customerlicense.amount) * parseFloat(customerlicense.finalprice)
        parseFloat(customerlicense.finalprice).toFixed(2);
      if (customerlicense.subscription) {
        return price + "€/Month";
      } else {
        return price + "€";
      }
    },
    getFee: function (customerlicense) {
      var price = parseFloat(customerlicense.finalprice);
      var fee =
        Number(
          this.templateById(customerlicense.templateid).partnermaintenancefee
        ) ?? 0;

      return ((price / 100) * fee).toFixed(2) + "€/Month";
    },
  },
  computed: {
    ...mapGetters({
      applicationById: "applicationById",
      templateById: "templateById",
      customerlicensesBySubcustomerIdActiveLicenses:
        "customerlicensesBySubcustomerIdActiveLicenses",
      customerlicensesBySubcustomerIdPendingLicenses:
        "customerlicensesBySubcustomerIdPendingLicenses",
      allApplicationsForFiltering: "allApplicationsForFiltering",
    }),
    customerLicenseTableHeader: function () {
      if (this.selectionEnabled) {
        return [
          {
            text: "Select",
            align: "start",
            sortable: false,
            value: "select",
          },
          {
            text: "Application",
            align: "center",
            sortable: false,
            value: "applicaiton",
          },
          {
            text: "Name",
            align: "center",
            sortable: false,
            value: "name",
          },
          {
            text: "Issued",
            align: "center",
            sortable: false,
            value: "issued",
          },
          /* {
            text: "Duration",
            align: "center",
            sortable: false,
            value: "duration",
          },
          {
            text: "Expires",
            align: "center",
            sortable: false,
            value: "expires",
          }, */
          {
            text: "Price",
            align: "center",
            sortable: false,
            value: "price",
          },
          {
            text: "Maintenance fee",
            align: "center",
            sortable: false,
            value: "fee",
          },
        ];
      } else {
        return [
          {
            text: "Application",
            align: "center",
            sortable: false,
            value: "applicaiton",
          },
          {
            text: "Name",
            align: "center",
            sortable: false,
            value: "name",
          },
          {
            text: "Issued",
            align: "center",
            sortable: false,
            value: "issued",
          },
          /* {
            text: "Duration",
            align: "center",
            sortable: false,
            value: "duration",
          },
          {
            text: "Expires",
            align: "center",
            sortable: false,
            value: "expires",
          }, */
          {
            text: "Price",
            align: "center",
            sortable: false,
            value: "price",
          },
          {
            text: "Maintenance fee",
            align: "center",
            sortable: false,
            value: "fee",
          },
        ];
      }
    },
  },
};
</script>

<style>
.license_pending {
  background-color: #00000025;
}
.license_blocked {
  background-color: #00000025;
}
.license_expires_one_week {
  background-color: #ff000025;
}
.license_expires_two_week {
  background-color: #ed920025;
}
.license_expires_four_week {
  background-color: #ffff0025;
}
</style>