<template>
  <v-dialog v-model="dialog" max-width="1500" persistent>
    <v-card>
      <v-card-title>
        <menu-title
          v-if="loggedInUserIsAdmin()"
          title="Create License"
        ></menu-title>
        <menu-title v-else title="License Request"></menu-title>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-switch
              v-model="subscription"
              align="left"
              class="ml-3"
              color="primary"
              inset
              label="Rental licence"
              :disabled="disableSubSwitch"
            ></v-switch>
          </v-col>
          <v-col cols="2">
            <v-switch
              v-model="nfr"
              align="left"
              class="ml-3"
              color="primary"
              inset
              label="Partner Testlicense"
              @change="nfrToggled"
            ></v-switch>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="applicationid"
              :items="allApplications"
              :item-text="(item) => item.name"
              :item-value="(item) => item.applicationid"
              class="ml-3"
              outlined
              label="Application"
              hide-details
              @change="selectedTemplate = selectDefault()"
            ></v-select>
          </v-col>
          <v-col>
            <v-text-field
              v-model="amount"
              type="number"
              min="1"
              label="Amount"
              outlined
              hide-details
              :rules="[exceedsLimit]"
            ></v-text-field>
          </v-col>
          <!-- <v-col v-if="subscription" cols="2">
            <v-text-field
              v-model="subscriptionLength"
              type="number"
              min="1"
              label="Runtime in Months"
              class="mr-3"
              outlined
              hide-details
            ></v-text-field>
          </v-col> -->
        </v-row>
        <v-row v-if="applicationid">
          <label v-if="templatesByApplicationId(applicationid).length == 0">
            No available licenses for selected application
          </label>
          <v-container v-else fluid>
            <v-row dense>
              <v-col
                cols="4"
                v-for="(template, index) in templatesByApplicationId(
                  applicationid
                )"
                :key="index"
              >
                <v-card outlined dense>
                  <v-card-title class="py-0">
                    <span style="color: #b31616">{{ template.name }}</span>
                    <v-spacer></v-spacer>
                    <v-checkbox
                      class="py-0"
                      v-model="selectedTemplate"
                      :value="template.id"
                      color="primary"
                    ></v-checkbox>
                  </v-card-title>
                  <v-card-text class="pt-0 pb-2">
                    <v-row>
                      <v-col cols="8" align="left">
                        {{ template.description }}
                      </v-col>
                      <v-col cols="4" align="right">
                        {{
                          subscription
                            ? nfr
                              ? "0€ / Month"
                              : template.monthlyprice + "€ / Month"
                            : nfr
                            ? "0€"
                            : template.price + "€"
                        }}
                      </v-col>
                      <!--
                      <v-col
                        v-if="!subscription"
                        cols="12"
                        align="left"
                      >
                        <span>* Maintenancefee of {{template.partnermaintenancefee}}% applies to this product ({{ }})</span>
                      </v-col>
                      -->
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-col cols="12" class="mt-3" v-if="!subscription">
              <span style="font-size: 115%"
                ><b
                  >Software maintenance fee per licence 10% of the end customer
                  price p.a.</b
                ></span
              >
              <br />
              <span style="font-size: 95%"
                ><b
                  >Invoice and software maintenance contract will be issued
                  separately</b
                ></span
              >
            </v-col>

            <v-col cols="12">
              <v-container align="center">
                <v-timeline dense clipped>
                  <!-- :color="getColour(index, discounts.length)" -->
                  <v-timeline-item
                    v-for="(discount, index) in discounts"
                    :key="index"
                    fill-dot
                    :color="discount.important ? '#b3161699' : '#66666699'"
                    :medium="discount.important"
                    :small="!discount.important"
                  >
                    <v-text-field
                      disabled
                      hide-details
                      solo
                      :label="discount.text"
                      :style="
                        discount.important
                          ? 'font-weight:bold'
                          : 'font-weight:normal'
                      "
                    >
                      <template v-slot:append>
                        <span
                          :style="
                            discount.important
                              ? 'font-weight:bold'
                              : 'font-weight:normal'
                          "
                        >
                          {{ nfr ? "0€" : discount.value }}
                        </span>
                      </template>
                    </v-text-field>
                  </v-timeline-item>
                </v-timeline>
              </v-container>
            </v-col>
          </v-container>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn outlined class="ml-3" @click="dialog = false">
          <span>Cancel</span>
        </v-btn>
        <!--
        <v-btn
          outlined
          @click="debug"
        >Debug</v-btn>
        -->
        <v-spacer></v-spacer>
        <!--
        <v-btn
          outlined
          @click="debug"
        >
          Debug
        </v-btn>
        -->
        <v-btn
          v-if="loggedInUserIsAdmin()"
          outlined
          color="primary"
          class="mr-3"
          @click="createNewLicenseForCustomer()"
        >
          <span>Create</span>
        </v-btn>
        <v-btn
          v-else
          outlined
          color="primary"
          class="mr-3"
          @click="beforeRequestConfirmation()"
        >
          Request
        </v-btn>
      </v-card-actions>
    </v-card>

    <request-confirmation-dialog
      v-if="requestConfirmationDialog"
      v-model="requestConfirmationDialog"
      @accepted="requestLicense"
      @close="dialog = false"
      :name="templateById(selectedTemplate).name"
      :amount="amount"
      :duration="subscriptionLength"
      :total="nfr ? '0€' : discounts[discounts.length - 1].value"
      :subscription="subscription"
      :subcustomer="subcustomer"
      :template="selectedTemplate"
      :parentDialog="dialog"
      :nfr="nfr"
    ></request-confirmation-dialog>
  </v-dialog>
</template>

<script>
import menuTitle from "../helpers/menu_title.vue";
import requestConfirmationDialog from "./customer_license_request_confirmation_dialog.vue";
import { mapActions, mapGetters } from "vuex";
import { verify } from "../../helpers/jwt";
import { emit } from "process";

export default {
  name: "LicenseRequestDialog",
  components: {
    menuTitle,
    requestConfirmationDialog,
  },
  props: {
    value: Boolean,
    subcustomer: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      allApplications: "allApplications",
      templateById: "templateById",
      templatesByApplicationId: "templatesByApplicationId",
      allTemplateModules: "allTemplateModules",
      quantitydiscountByTemplateIdAndAmount:
        "quantitydiscountByTemplateIdAndAmount",
      durationdiscountsByTemplateIdAndDuration:
        "durationdiscountsByTemplateIdAndDuration",
      activeUniqueCustomerlicensesByCustomerId:
        "activeUniqueCustomerlicensesByCustomerId",
    }),
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    discounts: function () {
      var discounts = [];
      var template = this.templateById(this.selectedTemplate);

      var originalPrice = parseFloat(
        this.subscription
          ? template.monthlyprice * this.amount ?? 0.0
          : template.price * this.amount ?? 0.0
      );

      discounts.push({
        text: "{0} x {1}".format(template.name, this.amount),
        discountPercentage: 0,
        discountValue: 0,
        value: this.subscription
          ? "{0}€/Month".format(originalPrice.toFixed(2))
          : "{0}€".format(originalPrice.toFixed(2)),
        important: true,
      });

      var partnerdiscount = parseFloat(template.partnerdiscount).toFixed(2);
      var partnerdiscountValue = this.getPercentageOf(
        originalPrice,
        partnerdiscount
      ).toFixed(2);
      discounts.push({
        text: "Partnerdiscount - {0}%".format(partnerdiscount),
        discountPercentage: partnerdiscount,
        discountValue: partnerdiscountValue,
        value: "-{0}€".format(partnerdiscountValue),
        important: false,
      });

      var quantityDiscount = this.quantitydiscountByTemplateIdAndAmount(
        this.selectedTemplate,
        this.amount
      );
      var quantityDiscountValue = this.getPercentageOf(
        originalPrice,
        parseFloat(quantityDiscount.discount)
      ).toFixed(2);
      discounts.push({
        text: "Quantity discount ({0} Licenses) - {1}%".format(
          quantityDiscount.amount,
          quantityDiscount.discount
        ),
        discountPercentage: parseFloat(quantityDiscount.discount),
        discountValue: quantityDiscountValue,
        value: "-{0}€".format(quantityDiscountValue),
        important: false,
      });

      if (this.subscription) {
        var durationDiscount = this.durationdiscountsByTemplateIdAndDuration(
          this.selectedTemplate,
          this.subscriptionLength
        );
        var durationDiscountValue = this.getPercentageOf(
          originalPrice,
          parseFloat(durationDiscount.discount)
        ).toFixed(2);
        discounts.push({
          text: "Duration discount ({0} Months) - {1}%".format(
            durationDiscount.duration,
            durationDiscount.discount
          ),
          discountPercentage: parseFloat(durationDiscount.discount),
          discountValue: durationDiscountValue,
          value: "-{0}€".format(durationDiscountValue),
          important: false,
        });
      }

      var total = originalPrice;
      discounts.forEach((discount) => {
        total -= discount.discountValue;
      });
      discounts.push({
        text: "Total including discounts",
        discountPercentage: 0,
        discountValue: 0,
        value: this.subscription
          ? "{0}€/Month".format(total.toFixed(2))
          : "{0}€".format(total.toFixed(2)),
        important: true,
      });

      return discounts;
    },
  },
  data: () => ({
    requestConfirmationDialog: false,
    subscription: false,
    disableSubSwitch: false,
    applicationid: null,
    amount: 1,
    subscriptionLength: 1,
    selectedTemplate: null,
    nfr: false,
  }),
  methods: {
    ...mapActions({
      fetchCustomerlicensesAsync: "fetchCustomerlicensesAsync",
      fetchApplicationsAsync: "fetchApplicationsAsync",
      fetchTemplatesAsync: "fetchTemplatesAsync",
      fetchQuantitydiscountsAsync: "fetchQuantitydiscountsAsync",
      fetchDurationdiscountsAsync: "fetchDurationdiscountsAsync",
      showSnackbar: "showSnackbar",
      postCustomerlicenseForAdmin: "postCustomerlicenseForAdmin",
    }),
    loggedInUserIsAdmin() {
      return verify(localStorage.getItem("jwt")).is_admin;
    },
    createNewLicenseForCustomer() {
      let purchaseid = String(Date.now());
      purchaseid += "_" + Math.random().toString().slice(2, 12);

      const license = {
        months: this.subscriptionLength,
        nfr: this.nfr,
        subcustomerid: this.subcustomer.customerid,
        templateid: this.selectedTemplate,
        subscription: this.subscription,
        purchaseid: purchaseid,
        amount: this.amount,
        customerid: this.subcustomer.customerof,
      };
      this.postCustomerlicenseForAdmin(license).then((val) => {
        if (val) {
          console.log(val);
          this.showSnackbar("A new License could not get Created");
        } else {
          this.showSnackbar("A new License was Created");
          this.$emit("done");
        }
      });
    },
    beforeRequestConfirmation: function () {
      if (!this.selectedTemplate)
        return this.showSnackbar("Please choose one of the options first");
      console.log(this.nfrLicensesAvailable());
      console.log(this.amount);
      if (this.nfr && Number(this.amount) > this.nfrLicensesAvailable())
        return this.showSnackbar(
          "Your selected amount exceeds the maximum amount of nfr licenses of that kind. You have {0} left unused".format(
            this.nfrLicensesAvailable()
          )
        );
      this.requestConfirmationDialog = true;
    },
    exceedsLimit: function (value) {
      if (!this.nfr) return true;
      if (!this.selectedTemplate) return true;
      if (this.nfrLicensesAvailable() >= value) return true;
      return "Exceeding maximum amount of nfr licenses for this license type";
    },
    nfrToggled: function (nv) {
      if (nv) {
        this.subscription = false;
        this.disableSubSwitch = true;
      } else {
        this.disableSubSwitch = false;
      }
    },
    requestLicense: function () {
      console.log("requesting licenses...");
    },
    getPercentageOf: function (total, percentage) {
      return parseFloat((parseFloat(total) / 100) * parseFloat(percentage));
    },
    getColour: function (modifier, max) {
      var base = "#b31616";
      var increase = 20;
      var transparencyStart = 255 - increase * max;
      var transparency = transparencyStart + increase * modifier;
      return base + transparency.toString(16);
    },
    selectDefault: function () {
      const template = this.templatesByApplicationId(this.applicationid)[0];
      if (template) return template.id;
      else return null;
    },
    nfrLicensesAvailable: function () {
      let max = this.templateById(this.selectedTemplate).freenfr;
      let taken = this.activeUniqueCustomerlicensesByCustomerId(
        this.subcustomer.customerof
      ).filter((x) => x.templateid == this.selectedTemplate).length;
      return max - taken;
    },
    debug: function () {
      this.nfrLicensesAvailable();
      return;
      console.log("Selected Template:");
      console.log(this.templateById(this.selectedTemplate));
      console.log(this.subcustomer);
      console.log(
        this.activeUniqueCustomerlicensesByCustomerId(
          this.subcustomer.customerof
        )
      );
    },
  },
  async mounted() {
    await this.fetchApplicationsAsync();
    await this.fetchTemplatesAsync();
    await this.fetchQuantitydiscountsAsync();
    await this.fetchDurationdiscountsAsync();
    await this.fetchCustomerlicensesAsync();
  },
};
</script>

<style>
</style>